var jumpToSection, scrollLink, scrollToElement;

scrollToElement = function(elem, padding, speed, easing) {
  "use strict";
  var $window, elemOffset, mainElement, removeScrollingClass, wheight;
  elem = typeof elem !== 'undefined' ? elem : "html, body";
  padding = typeof padding !== 'undefined' ? padding : 0;
  speed = typeof speed !== 'undefined' ? speed : 400;
  easing = typeof easing !== 'undefined' ? easing : "swing";
  mainElement = $('html, body');
  $window = $(window);
  removeScrollingClass = function() {
    return $('html').removeClass('scrolling');
  };
  if ($(elem).length) {
    elemOffset = $(elem).offset().top - $('body').offset().top;
    return mainElement.stop().animate({
      scrollTop: (elemOffset - padding) + "px"
    }, speed, easing, removeScrollingClass);
  } else if (elem === '#top') {
    return mainElement.animate({
      scrollTop: 0
    }, speed, easing, removeScrollingClass);
  } else if (elem === '#bottom') {
    wheight = $window.height();
    return mainElement.animate({
      scrollTop: wheight
    }, speed, easing, removeScrollingClass);
  } else if (elem === '#down') {
    return mainElement.animate({
      scrollTop: "" + ($window.scrollTop() + 300)
    }, speed, easing, removeScrollingClass);
  }
};

scrollLink = function() {
  "use strict";
  return $('.scroll-to').on('click', function(e) {
    var $this, offset, target;
    e.preventDefault();
    $this = $(this);
    target = null;
    offset = 0;
    if (!$this.data('target')) {
      target = $this.attr('href');
    } else {
      target = $this.data('target');
    }
    if ($this.data('offset')) {
      offset = offset + $this.data('offset');
    }
    $('html').addClass('scrolling');
    scrollToElement(target, offset);
    return false;
  });
};

jumpToSection = function() {
  "use strict";
  var offsetHeight, sectionID, sectionOffset;
  if ($('#jumpToSection').length) {
    sectionID = $('#jumpToSection').val();
    offsetHeight = 57;
    sectionOffset = ($('#' + sectionID).offset().top) - offsetHeight;
    return $(window).scrollTop(sectionOffset);
  }
};

$(window).on('load', function() {
  "use strict";
  scrollLink();
  jumpToSection();
});
